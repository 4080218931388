<template>
  <div class="columns no-margin">
    <div class="column no-padding is-full">
      <div class="card">
        <header class="card-header has-background-primary">
          <p class="card-header-title has-text-light is-uppercase">
            {{ headerText }} AKTIVITAS KETERAMPILAN
          </p>
        </header>
        <div class="card-content events-card">
          <b-field class="field-spacer"></b-field>
          <template v-if="errorMap.non_field_errors">
            <p class="help is-danger">{{ errorMap.non_field_errors }}</p>
            <b-field class="field-spacer"></b-field>
          </template>

          <b-field
            label="Lokasi"
            class="ac-field"
            custom-class="is-small notrequired"
            :type="{ 'is-danger': errorMap.lokasi }"
            :message="errorMap.lokasi"
          >
            <generic-autocomplete
              apiPath="/perawatan/lokasi/"
              v-model="aktKomponen.lokasi"
              @input="validateInput('lokasi')"
              @typing="onLokasiACTyping"
              :required="false"
            ></generic-autocomplete>
            <div class="control">
              <router-link :to="lokasiAddTo">
                <b-button class="is-primary">
                  <b-icon icon="plus" />
                </b-button>
              </router-link>
            </div>
            <div :v-show="false">
              <!-- diperlukan agar menjadi addons, terkait permasalahan di method
              b-field.hasAddons dimana kalkulasi jumlah node di slot ini, baru button-nya
              yang terhitung. dan tidak di-watch. kemungkinan terkait race-condition.
              -->
            </div>
          </b-field>

          <b-field
            label="Keterampilan"
            class="ac-field"
            custom-class="is-small"
            :type="{ 'is-danger': errorMap.keterampilan }"
            :message="errorMap.keterampilan"
          >
            <generic-autocomplete
              apiPath="/penyakit/keterampilan/"
              :params="ketOptionsParamsUrl"
              v-model="aktKomponen.keterampilan"
              @input="validateInput('keterampilan')"
              @typing="onKeterampilanACTyping"
            ></generic-autocomplete>

            <div class="control">
              <router-link :to="keterampilanAddTo">
                <b-button class="is-primary">
                  <b-icon icon="plus" />
                </b-button>
              </router-link>
            </div>
            <div :v-show="false">
              <!-- diperlukan agar menjadi addons, terkait permasalahan di method
              b-field.hasAddons dimana kalkulasi jumlah node di slot ini, baru button-nya
              yang terhitung. dan tidak di-watch. kemungkinan terkait race-condition.
              -->
            </div>
          </b-field>

          <b-field
            label="Catatan"
            custom-class="is-small notrequired"
            :type="{ 'is-danger': errorMap.catatan }"
            :message="errorMap.catatan"
          >
            <b-input
              v-model="aktKomponen.catatan"
              type="textarea"
              @input="validateInput('catatan')"
            ></b-input>
          </b-field>

          <b-field
            v-if="isCreate"
            class="file"
            :type="{ 'is-danger': errorMap.foto }"
            :message="errorMap.foto"
          >
            <b-upload
              v-model="fotoMdl"
              @input="resize"
              accept=".jpg, .jpeg, .png"
            >
              <a class="button is-primary">
                <b-icon icon="upload"></b-icon>
                <span>Upload Foto</span>
              </a>
            </b-upload>
            <span class="file-name" v-if="fotoMdl">{{ fotoMdl.name }}</span>
          </b-field>

          <!-- perlu diganti dengan div spacing -->
          <b-field class="field-spacer"></b-field>

          <div class="buttons">
            <b-button
              class="is-primary has-text-weight-semibold"
              @click.stop="cancel"
              >Batal</b-button
            >
            <b-button
              class="is-primary has-text-weight-semibold"
              :disabled="!isValid"
              @click.stop.prevent="saveObject(!fotoMdl)"
              >Simpan</b-button
            >
          </div>
          <b-loading :is-full-page="false" :active.sync="loading"></b-loading>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import createUpdateMixin from "@/apps/core/mixins/createUpdateMixin";
import AktivitasKeterampilan from "../models/cuKetVirtual.js";
import UploadPhoto from "@/apps/core/modules/uploadPhoto.js";

export default {
  name: "KeterampilanVirtualCreateUpdate",
  props: ["headerText"],
  mixins: [createUpdateMixin],
  components: {
    GenericAutocomplete: () =>
      import("@/apps/core/components/GenericAutocomplete.vue"),
  },
  data() {
    this.aktivitasKetMdl = new AktivitasKeterampilan();
    this.objectMdl = this.aktivitasKetMdl; // alias yg digunakan di cuMixin
    let obv = this.aktivitasKetMdl.getObservables();
    obv.sub_departemen_id = this.$route.params.subDeptId;
    obv.aktivitas_id = this.$route.params.aktivitasId;
    obv.keterampilanInput = "";
    obv.lokasiInput = "";
    obv.foto = null;
    obv.fotoMdl = null;
    this.uploadPhoto = new UploadPhoto("aktivitasketerampilan");
    return obv;
  },
  computed: {
    ...mapState("aktivitas", {
      aktKomponenState: "aktKomponen",
      aktLokasiState: "aktLokasi",
    }),
    keterampilanAddTo() {
      const aktKomponen = this.getaktKomponen();
      let subDeptId = this.$route.params.subDeptId;
      if (!this.isCreate) {
        subDeptId = aktKomponen.aktivitas.sub_departemen.id;
      }
      let params = { aktKomponen: aktKomponen, id: subDeptId };
      return {
        name: "subdept-keterampilan-add",
        params: params,
      };
    },
    lokasiAddTo() {
      const aktKomponen = this.getaktKomponen();
      return {
        name: "lokasi-add",
        params: { aktLokasi: aktKomponen },
      };
    },
    ketOptionsParamsUrl() {
      // let url = `/penyakit/keterampilan/`;
      let subDeptId = this.$route.params.subDeptId;
      if (!this.isCreate && this.isLoaded) {
        subDeptId = this.aktKomponen.aktivitas.sub_departemen.id;
      }
      return { sub_departemen: subDeptId };
      // return `${url}?sub_departemen=${subDeptId}`;
    },
  },
  methods: {
    ...mapActions("aktivitas", ["setAktKomponen", "setAktLokasi"]),
    onKeterampilanACTyping(value) {
      this.keterampilanInput = value;
    },
    onLokasiACTyping(value) {
      this.lokasiInput = value;
    },
    getaktKomponen() {
      const aktKomponen = JSON.parse(JSON.stringify(this.aktKomponen));
      if (!this.isCreate) {
        aktKomponen["edited"] = this.aktivitasKetMdl.getEdited();
      }
      return aktKomponen;
    },
    fetchData() {
      if (!this.isCreate) {
        this.aktivitasKetMdl.setRequiredFields(["keterampilan"]);
        delete this.errorMap["foto"];
        delete this.errorMap["tanggal"];
        delete this.errorMap["preceptor"];
      }
      if (this.aktKomponenState || this.aktLokasiState) {
        let aktState = this.aktKomponenState
          ? this.aktKomponenState
          : this.aktLokasiState;
        this.aktKomponen = JSON.parse(JSON.stringify(aktState));
        if (this.isCreate) {
          // this.aktKomponen.aktivitas = this.$route.params.aktivitasId;
          this.aktKomponen.foto = null;
          this.uploadPhoto.requestAWS();
        } else {
          this.setEdited(this.aktKomponen["edited"]);
        }

        for (const field of this.aktivitasKetMdl.getRequiredFields()) {
          if (this.aktKomponen[field]) {
            this.validateInput(field);
          }
        }
        this.setAktKomponen(null);
        this.setAktLokasi(null);
      } else if (!this.isCreate) {
        this.objectMdl.load(this.$route.params.id);
      } else {
        // this.aktKomponen.aktivitas = this.$route.params.aktivitasId;
        this.aktKomponen.foto = null;
        this.uploadPhoto.requestAWS();
      }
    },
    resize(foto) {
      this.uploadPhoto.resize(foto, (foto, url) => {
        this.foto = foto;
        this.aktKomponen.foto = url;
        this.validateInput("foto");
      });
    },
    upload() {
      this.uploadPhoto.upload(this.foto, this.saveObject, () => {
        this.aktKomponen.foto = null;
        this.foto = null;
        this.fotoMdl = null;
      });
    },
    onSaved(respObj) {
      if (this.isCreate) {
        this.$router.replace({
          name: "aktivitas-keterampilan-detail",
          params: { id: respObj.id },
        });
      } else {
        this.$router.go(-1);
      }
    },
    saveObject(commit) {
      this.objectMdl.setCommit(commit);
      if (commit) {
        this.objectMdl.save(this.saveContext, this.onSaved);
      } else {
        this.objectMdl.save(this.saveContext, this.upload);
      }
    },
  },
  watch: {
    aktKomponen: {
      // aktivitas berubah, update edited
      handler(newValue, oldValue) {
        if (!this.aktivitasKetMdl.getEdited()) {
          this.setEdited(this.isCreate || !!oldValue.id);
        }
      },
      deep: true,
    },
  },
};
</script>

<style scoped lang="scss">
@media screen and (min-width: 769px), print {
  .field-label {
    flex-grow: 0;
    text-align: left;
  }
}

::v-deep .ac-field {
  button {
    border-bottom-left-radius: 0 !important;
    border-top-left-radius: 0 !important;
    border-top-right-radius: 4px !important;
    border-bottom-right-radius: 4px !important;
  }

  p.help {
    display: none;
  }
}
</style>
