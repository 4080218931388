import ModelBase from "@/apps/core/models/modelBase.js";

const modelName = "aktKomponen";
const modelPath = "/aktivitas/keterampilan-virtual/";

class AktivitasKeterampilan extends ModelBase {
  constructor() {
    let model = {
      id: null,
      keterampilan: null,
      foto: null,
      lokasi: null,
      catatan: ""
    };
    super(modelName, model, ["keterampilan"], ["foto", "lokasi", "catatan"], modelPath);
  }

  getLoadData(data) {
    let tdata = super.getLoadData(data);
    tdata.keterampilan = tdata.keterampilan.id;
    if (tdata.lokasi) {
      tdata.lokasi = tdata.lokasi.id;
    }
    return tdata;
  }

  getPayload() {
    let data = super.getPayload();
    if (this.observables.sub_departemen_id) data.sub_departemen = this.observables.sub_departemen_id;
    if (this.observables.aktivitas_id) data.aktivitas = this.observables.aktivitas_id;
    return data;
  }
}

export default AktivitasKeterampilan;
